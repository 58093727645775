export const routes = {
  home: "/",
  amstaff: "/amstaff",
  bulterier: "/bulterier",
  americanBully: "/american-bully",
  doberman: "/doberman",
  cavalier: "/cavalier",
} as const;

export type AppRoutes = keyof typeof routes;
