import { createBrowserRouter } from "react-router-dom";
import Homepage from "./views/homepage";
import { routes } from "./consts/routes";
import AmstaffBreedPage from "./views/amstaff";
import AmericanBullyBreedPage from "./views/american-bully";
import BullterrierBreedPage from "./views/bullterrier";
import DobermanBreedPage from "./views/doberman";
import CavalierBreedPage from "./views/cavalier";
import { Layout } from "./ui/components/layout";

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <Homepage />,
  },
  {
    path: routes.amstaff,
    element: <AmstaffBreedPage />,
  },
  {
    path: routes.bulterier,
    element: <BullterrierBreedPage />,
  },
  {
    path: routes.americanBully,
    element: <AmericanBullyBreedPage />,
  },
  {
    path: routes.doberman,
    element: <DobermanBreedPage />,
  },
  {
    path: routes.cavalier,
    element: <CavalierBreedPage />,
  },
  {
    path: "*",
    element: (
      <Layout>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <h1
            style={{
              marginTop: "128px",
            }}
          >
            404 - Not found
          </h1>
        </div>
      </Layout>
    ),
  },
]);

export { router };
