import { PageWrapper } from "../ui/components/page-wrapper";
import { CavalierHeroSection } from "../ui/sections/cavalier/hero";

const CavalierBreedPage = () => {
  return (
    <PageWrapper>
      <CavalierHeroSection />
    </PageWrapper>
  );
};

export default CavalierBreedPage;
