import { PageWrapper } from "../ui/components/page-wrapper";
import { DobermanHeroSection } from "../ui/sections/doberman/hero";

const DobermanBreedPage = () => {
  return (
    <PageWrapper>
      <DobermanHeroSection />
    </PageWrapper>
  );
};

export default DobermanBreedPage;
